

import "../css/styles.css";


import { useState } from "react";

import { SpeechRecognitionContextProvider } from "../context/SpeechRecognitionContext";
import { SpeechSynthesisContextProvider } from "../context/SpeechSynthesisContext";
import { PresentationContextProvider } from "../context/PresentationContext";
import { CurriculumContextProvider } from "../context/CurriculumContext";
import { MessageContextProvider } from "../context/MessageContext";
import { PromptContextProvider } from "../context/PromptContext";
import { AIBotsContextProvider } from "../context/AIBotsContext";
import { AdminContextProvider } from "../context/AdminContext";
import { useSession } from "../context/SessionContext";


import PresentationContainer from "./PresentationContainer";
import AIChatOrchestrator from "./AIChatOrchestrator";
import LectureContainer from "./LectureContainer";
import ChatContainer from "./ChatContainer";
import { AdminToggle } from "./AdminPanel";

import { AppMode } from "../types/enums";


export default function AppBody() {


  const { appMode } = useSession();


  const renderAppMode = () => {

    switch( appMode ) {
      case AppMode.PRESENTATION: {
        return (
          <PresentationContextProvider >
            <PresentationContainer />
          </PresentationContextProvider>
        );
      }

      case AppMode.TEXT: {
        return (
          <>
            {/* renderAIChatOrchestrator() */}
            <LectureContainer />
          </>
        )
      }
    }
  };

  return (
    <div className="container-body">
      <SpeechSynthesisContextProvider>
        <CurriculumContextProvider>
          <PromptContextProvider>

            <MessageContextProvider >
              <SpeechRecognitionContextProvider>

                <AIBotsContextProvider>
                  <AdminContextProvider > {/* TODO: Decide if want move to App.tsx upper layers as well */}
                    <AdminToggle />

                    { renderAppMode() }
                    {/* TODO: Was considering a Context for overlapping code between Lecture and Presentation, such as the Navigation means (Start/Pause/Play, Next)
                    <InstructionContext>
                      { renderAppMode() }
                    </InstructionContext>
                    */}

                    <ChatContainer />

                  </AdminContextProvider>
                </AIBotsContextProvider>

              </SpeechRecognitionContextProvider>
            </MessageContextProvider>

          </PromptContextProvider>
        </CurriculumContextProvider>
      </SpeechSynthesisContextProvider>
    </div>
  );
}




/* DEPRECATED in favor of AIBotsContextProvider -- May weed-out entirely here soon
function renderAIChatOrchestrator() {

  const { curriculumDescription, maxRounds, autoStart } = getAIBotsConfig();

  const initialPrompt = `
    Start the lecture by introducing yourself and the topic in an apporpirate way.
  `;

  return (
    <AIChatOrchestrator
      curriculumDescription={curriculumDescription}
      initialPrompt={initialPrompt}
      maxRounds={3}
      onError={(error) => console.error('Chat error:', error)}
    />
  );
}
*/



