

import { useState, useContext, createContext } from "react";

import { AdminOverlay } from "../components/AdminPanel";

import { PromptTemplateType, usePromptUpdate } from "./PromptContext";




// TODO: Consider moving elsewhere?
interface AdminSection {
  id: string;
  label: string;
  subsections: Record<string, string>;
}




interface AdminContextType {
    isAdminMode: boolean;

    selectedSection: string;
    selectedSubsection: string;
    sections: Record<string, AdminSection>;
}

interface AdminUpdateContextType {
    toggleAdminMode: () => void;

    changeSelectedSection: (section: string) => void;
    changeSelectedSubsection: (subsection: string) => void;
}


const AdminContext = createContext<AdminContextType | null>(null);
const AdminUpdateContext = createContext<AdminUpdateContextType | null>(null);


function useAdmin() {
    const context = useContext(AdminContext);
    if (!context) throw new Error("useAdmin must be used within AdminContextProvider");
    return context;
}

function useAdminUpdate() {
    const context = useContext(AdminUpdateContext);
    if (!context) throw new Error("useAdminUpdate must be used within AdminContextProvider");
    return context;
}




interface ComponentProps {
    children: React.ReactNode;
}

function AdminContextProvider({ children }: ComponentProps) {
    const [isAdminMode, setIsAdminMode] = useState<boolean>( false );

    const { getPromptCategories } = usePromptUpdate();


    const [selectedSection, setSelectedSection] = useState<string>("prompts");
    const [selectedSubsection, setSelectedSubsection] = useState<string>("context");
    
    const sections: Record<string, AdminSection> = {
        prompts: {
            id: "prompts",
            label: "Prompts",
            subsections: getPromptCategories().reduce((acc, category) => {
                acc[category] = category;
                return acc;
            }, {} as Record<string, string>) || {}
        },

        curriculum: {
            id: "curriculum",
            label: "Curriculum",
            subsections: {
                // TODO: Generate / get from elsewhere (not hardcode)
                description: "Description",
                objectives: "Learning Objectives",
                prerequisites: "Prerequisites",

                keywords: "Keywords",
                subject: "Subject",
                topic: "Topic",

                goalStatement: "Goal Statement",

                learnerLevel: "Learner Level",
                learnerAge: "Learner Age",

                sessionLength: "Session Length",
                numberOfSessions: "Number of Sessions",

                assessmentType: "Assessment Type",

                learningPreference: "Learning Preference",

                specialRequests: "Special Requests"
            }
        },

        promptsGenerator: {
            id: "promptsGenerator",
            label: "Prompts Generator",
            subsections: getPromptCategories(PromptTemplateType.GENERATED).reduce((acc, category) => {
                acc[category] = category;
                return acc;
            }, {} as Record<string, string>) || {}
        }
    };

    const changeSelectedSection = (section: string) => {
        setSelectedSection(section);
    };

    const changeSelectedSubsection = (subsection: string) => {
        setSelectedSubsection(subsection);
    };



    const toggleAdminMode = () => {
        setIsAdminMode(previous => !previous);
    };


    return (
        <AdminContext.Provider value={{ isAdminMode, selectedSection, selectedSubsection, sections }}>
            <AdminUpdateContext.Provider value={{ toggleAdminMode, changeSelectedSection, changeSelectedSubsection }}>
                {children}
                {isAdminMode && <AdminOverlay />}
            </AdminUpdateContext.Provider>
        </AdminContext.Provider>
    );
}



export {
    AdminContextProvider,
    useAdmin, useAdminUpdate
};

