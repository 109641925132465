
import { useState, useContext, createContext, useCallback, useRef, useEffect } from "react";

import { AppMode, MessageType, PromptType } from "../types/enums";

import { useSession, useSessionUpdate } from "./SessionContext";
import { PromptTemplateType, usePromptUpdate } from "./PromptContext";
import { useCurriculum, useCurriculumUpdate } from "./CurriculumContext";
import { useProfileUpdate } from "./ProfileContext";
import { useMessage, useMessageUpdate } from "./MessageContext";

import { INSTRUCTOR_NAME, FAKE_STUDENT_NAME, USE_GENERATED_RESPONSES, USE_STUDENT_BOT, PROBABILITY_STUDENT_BOT_RESPONDS } from "../common/constants";





interface ChatMessage { // TODO: Use IMessage from type file instead?
    text: string;
    sender: string;
    type: MessageType;
}

interface ChatContext {
    //curriculumDescription: string;
    previousMessages: Array<{
        text: string;
        sender: string;
        timestamp: number;
        type: MessageType;
    }>;
}



interface AIBotsContextType {
    isProcessing: boolean;
    currentRound: number;
    setCurrentRound: (round: number) => void;
    isRoundComplete: boolean;
}

interface AIBotsUpdateContextType {
    generateInstructorLecture: (prompt: string) => Promise<void>;
    generateStudentBotResponse: (instructorMessage: string) => Promise<void>;
    generateInstructorResponseToStudentBot: (studentMessage: string) => Promise<void>;
    generateInstructorResponseToHuman: (studentMessage: string) => Promise<void>;
    generateLectureSummary: () => Promise<void>;

    startNextRound: () => void;
    clearCurrentSession: () => void;
    setMaxRounds: (rounds: number) => void;

    initializeAIBots: () => Promise<void>;
}



const AIBotsContext = createContext<AIBotsContextType | null>(null);
const AIBotsUpdateContext = createContext<AIBotsUpdateContextType | null>(null);




function useAIBots() {
    const context = useContext(AIBotsContext);
    if (!context) throw new Error("useAIBots must be used within AIBotsContextProvider");
    return context;
}

function useAIBotsUpdate() {
    const context = useContext(AIBotsUpdateContext);
    if (!context) throw new Error("useAIBotsUpdate must be used within AIBotsContextProvider");
    return context;
}




const AUTOMATIC_START_NEXT_ROUND = false;
const START_NEXT_ROUND_TIMEOUT = 20000;



const USE_TEACHING_GUIDELINES = false; // TODO: Move to constants file ??
const USE_REAL_STUDENT_ENGAGEMENT_GUIDELINES = false; // TODO: Move to constants file ??

// TODO: Move to constants file ??
const NUMBER_OF_ROUNDS_TO_KEEP_IN_CONVERSATION_HISTORY = 1;
const MAX_CONVERSATION_HISTORY_LENGTH = NUMBER_OF_ROUNDS_TO_KEEP_IN_CONVERSATION_HISTORY * 3;



interface AIBotsProviderProps {
  children: React.ReactNode;
  maxRounds?: number;
}
function AIBotsContextProvider({
    children,
    maxRounds = Infinity,
}: AIBotsProviderProps) {

    const [aiBotsInitialized, setAIBotsInitialized] = useState( false );
    const [isProcessing, setIsProcessing] = useState( false );
    const [currentRound, setCurrentRound] = useState( 0 );
    const [isRoundComplete, setIsRoundComplete] = useState( false );
    const maxRoundsRef = useRef( maxRounds );
    const lectureProgressRef = useRef<string>( "" );
    const lastLectureRoundRef = useRef<string>( "" );
    const conversationHistoryRef = useRef<ChatMessage[]>( [] );

    const { curriculum, compiledCurriculum } = useCurriculum();
    const { updateCurriculumField } = useCurriculumUpdate();

    const { unmuteStudents } = useSessionUpdate();

    const { getCompiledPrompt, handleQuestion, handleGeneralPrompt, handleGeneratePrompt, handleReviseGeneratedPrompt, handleGenerateAndRevisePrompt, handleCompileGenerateAndRevisePrompts, handleCompileGenerateAndPublishPrompts, handleCompileAndExecutePrompt, updatePromptTemplateResponse } = usePromptUpdate();

    const { postMessageAndSpeakInChunks } = useMessageUpdate();
    const { getHumanStudentName } = useProfileUpdate();

    const { appMode } = useSession();

    const [ teachingGuidelines, setTeachingGuidelines ] = useState<string>( "" ); // TODO: Something better to initialize it with?
    const [ realStudentEngagementGuidelines, setRealStudentEngagementGuidelines ] = useState<string>( "" ); // TODO: Something better to initialize it with?



    useEffect( () => {
        if( !aiBotsInitialized ) return;

        const updateTeachingGuidelines = async () => {
            const newTeachingGuidelines = await getTeachingGuidelines();
            setTeachingGuidelines( newTeachingGuidelines );
        };

        const updateRealStudentEngagementGuidelines = async () => {
            const newRealStudentEngagementGuidelines = await getRealStudentEngagementGuidelines();
            setRealStudentEngagementGuidelines( newRealStudentEngagementGuidelines );
        };

        if( USE_TEACHING_GUIDELINES ) {
            updateTeachingGuidelines();
        }

        if( USE_REAL_STUDENT_ENGAGEMENT_GUIDELINES ) {
            updateRealStudentEngagementGuidelines();
        }
    }, [aiBotsInitialized, curriculum]);




    const initializeAIBots = async () => {
        const newCurriculumDescription = await getCurriculumDescription();
        updateCurriculumField( "description", newCurriculumDescription );

        setAIBotsInitialized( true );
    };





    const updateConversationHistory = useCallback((message: ChatMessage) => {
        conversationHistoryRef.current = [...conversationHistoryRef.current, message];

        // Trim conversation history to max length if max length is set
        if( MAX_CONVERSATION_HISTORY_LENGTH && conversationHistoryRef.current.length > MAX_CONVERSATION_HISTORY_LENGTH ) {
            conversationHistoryRef.current = conversationHistoryRef.current.slice(-MAX_CONVERSATION_HISTORY_LENGTH);
        }
    }, []);


    // TODO: Consider removing this and enforcing prompt always including {template} to refer to specific dynamic data insead of here... ?? - Or see if can just keep somethign like last lecture point or something?
    const getUpdatedContext = useCallback(() => {
        /* now being included dynamically via "{curriculum}"
        return `
            Curriculum Description: ${curriculum.description}
        */
        return `
            Current Conversation History: ${conversationHistoryRef.current
                                            .map(msg => `${msg.sender}: ${msg.text}`)
                                            .join("\n")}
        `;
        /* now being included dynamically via "{lecture}"
            Last Lecture Point: ${lectureProgressRef.current}
        `;
        */
    }, [curriculum, conversationHistoryRef, lectureProgressRef]);



    const generateInstructorLecture = useCallback(async (prompt: string): Promise<void> => {
        setIsRoundComplete( false );

        try {
            const context = `
                ${getUpdatedContext()}

                ${USE_TEACHING_GUIDELINES ? `Follow these teaching guidelines: ${teachingGuidelines}` : ""}
            `;

            prompt = `${context}\n${prompt}`; // TODO: better way add context in? (do like for transcript / presentation context where the handleGeneralPrompt alredy passes, but need add to SessionContext or something)
            const lectureResponse = await handleGeneralPrompt(prompt);


            console.log("(generateInstructorLecture) GENERATE INTRUCTOR LECTURE PROMPT:", prompt);

            if (lectureResponse) {
                lectureProgressRef.current = lectureResponse;
                const studentBotShouldRespond = USE_STUDENT_BOT && Math.random() > 1 - PROBABILITY_STUDENT_BOT_RESPONDS;

                await postMessageAndSpeakInChunks(
                    {
                        message: lectureResponse,
                        sender: INSTRUCTOR_NAME,
                        type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                    },
                    {},
                    studentBotShouldRespond ? () => generateStudentBotResponse(lectureResponse) : handleEndRound
                );

                updateConversationHistory({
                    text: lectureResponse,
                    sender: INSTRUCTOR_NAME,
                    type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                });
            }
        } catch (error) {
            console.error("Error in generateInstructorLecture:", error);
            setIsProcessing(false);
        }
    }, [handleGeneralPrompt, postMessageAndSpeakInChunks, getUpdatedContext, teachingGuidelines, appMode]);

    const generateStudentBotResponse = useCallback(async (messageToRespondTo: string): Promise<void> => {
        try {
            let studentResponsePrompt = "";
            if( USE_GENERATED_RESPONSES ) {
                studentResponsePrompt = await handleCompileGenerateAndRevisePrompts( "draftStudentBotResponse", {}, 
                                                                                     "publishStudentBotResponse", {} );
            } else {
                studentResponsePrompt = getCompiledPrompt( "createStudentBotResponse", { curriculum: compiledCurriculum } );
            }

            // TODO: The context here should be actual context, not mixed with prompt
            const prompt = `
                ${getUpdatedContext()}
                ${studentResponsePrompt}

                The student is responding to: "${messageToRespondTo}"
            `;

            const studentResponse = await handleQuestion(prompt, FAKE_STUDENT_NAME);

            if( studentResponse ) {
                await postMessageAndSpeakInChunks(
                    {
                        message: studentResponse,
                        sender: FAKE_STUDENT_NAME,
                        type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                    },
                    {},
                    () => generateInstructorResponseToStudentBot(studentResponse)
                );

                updateConversationHistory({
                    text: studentResponse,
                    sender: FAKE_STUDENT_NAME,
                    type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                });
            }
        } catch( error ) {
            console.error("Error in generateStudentBotResponse:", error);
            setIsProcessing(false);
        }
    }, [handleQuestion, postMessageAndSpeakInChunks, getUpdatedContext, compiledCurriculum]);

    const generateInstructorResponseToStudentBot = useCallback(async (messageToRespondTo: string): Promise<void> => {
        try {
            let instructorResponsePrompt = "";
            if( USE_GENERATED_RESPONSES ) {
                instructorResponsePrompt = await handleCompileGenerateAndRevisePrompts( "draftInstructorResponseToStudentBot", {}, 
                                                                                        "publishInstructorResponseToStudentBot", {} );
            } else {
                instructorResponsePrompt = getCompiledPrompt( "createInstructorResponseToStudentBot", { curriculum: compiledCurriculum } );
            }

            // TODO: The context here should be actual context, not mixed with prompt
            // TODO: This whole thing is being taken as the PROMPT/QUESTION but it's not all prompt...
            const prompt = `
                ${getUpdatedContext()}
                ${instructorResponsePrompt}

                The student's message was: "${messageToRespondTo}"
            `;

            const handleInstructorResponseComplete = async (prompt: string) => {
                handleEndRound();
            };

            //const instructorResponse = await handleQuestion(studentMessage, INSTRUCTOR_NAME);
            const instructorResponse = await handleQuestion(prompt, FAKE_STUDENT_NAME);

            if (instructorResponse) {
                updateConversationHistory({
                    text: instructorResponse,
                    sender: INSTRUCTOR_NAME,
                    type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                });

                await postMessageAndSpeakInChunks(
                    {
                        message: instructorResponse,
                        sender: INSTRUCTOR_NAME,
                        type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                    },
                    {},
                    handleInstructorResponseComplete
                );

                updateConversationHistory({
                    text: instructorResponse,
                    sender: INSTRUCTOR_NAME,
                    type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                });
            }
        } catch (error) {
            console.error("Error in generateInstructorResponseToStudentBot:", error);
            setIsProcessing(false);
        }
    }, [handleQuestion, postMessageAndSpeakInChunks, getUpdatedContext, compiledCurriculum]);


    const generateInstructorResponseToHuman = useCallback(async (messageToRespondTo: string): Promise<void> => {
        try {
            let instructorResponsePrompt = "";
            /*
            if( USE_GENERATED_RESPONSES ) {
                // TODO: Move to single helper function? like generateAndPublishPrompt ??
                const draftPrompt = getCompiledPrompt( "draftInstructorBotEngageRealStudentGuidelines" );
                const publishPrompt = getCompiledPrompt( "publishInstructorBotEngageRealStudentGuidelines" );
                const generatedPrompt = await handleGeneratePrompt( draftPrompt );
                const revisedPrompt = await handleReviseGeneratedPrompt( publishPrompt, generatedPrompt );
                instructorResponsePrompt = revisedPrompt;
            } else {
                instructorResponsePrompt = getCompiledPrompt( "instructorPromptResponseToStudentBot" );
            }
            */

            // TODO: The context here should be actual context, not mixed with prompt
            // TODO: This whole thing is being taken as the PROMPT/QUESTION but it's not all prompt...
            const prompt = `
                ${getUpdatedContext()}
                ${USE_TEACHING_GUIDELINES ? `Follow these teaching guidelines: ${teachingGuidelines}` : ""}
                ${USE_REAL_STUDENT_ENGAGEMENT_GUIDELINES ? `Follow these human engagement guidelines: ${realStudentEngagementGuidelines}` : ""}

                Respond to the student.
                The student's message was: "${messageToRespondTo}"
            `;

            //const instructorResponse = await handleQuestion(studentMessage, INSTRUCTOR_NAME);
            const instructorResponse = await handleQuestion(prompt, getHumanStudentName());

            if (instructorResponse) {
                updateConversationHistory({
                    text: instructorResponse,
                    sender: INSTRUCTOR_NAME,
                    type: MessageType.CHAT
                });

                await postMessageAndSpeakInChunks(
                    {
                        message: instructorResponse,
                        sender: INSTRUCTOR_NAME,
                        type: MessageType.CHAT
                    },
                    {},
                    //AUTOMATIC_START_NEXT_ROUND ? startNextRound : () => {}
                );
            }
        } catch (error) {
            console.error("Error in generateInstructorResponseToHuman:", error);
            setIsProcessing(false);
        }
    }, [handleQuestion, postMessageAndSpeakInChunks, getUpdatedContext]);




    const generateLectureSummary = useCallback(async () => {
        const summary = await handleCompileAndExecutePrompt( "createLectureSummary", { lecture: lectureProgressRef.current, curriculum: compiledCurriculum } );
        return summary;
    }, [handleCompileAndExecutePrompt, compiledCurriculum]);

    const getNextRoundTopic = useCallback(async () => {
        const nextTopic = await handleCompileAndExecutePrompt( "createNextRoundTopic", { lecture: lectureProgressRef.current, curriculum: compiledCurriculum } );
        return nextTopic;
    }, [handleCompileAndExecutePrompt, compiledCurriculum]);




    // TODO: "Rounds" doesn't really make sense in this context...
    // ... Consider moving this to some sort of "AILecture" Component, "AutomatedLecture", or something...
    const startNextRound = useCallback(async () => {

        setIsRoundComplete( false );

        //const lectureSummary = await generateLectureSummary();
        //console.log("(startNextRound) GENERATE LECTURE SUMMARY - lectureSummary:", lectureSummary);

        const nextRoundTopic = await getNextRoundTopic();

        if (currentRound < maxRoundsRef.current) {
            setCurrentRound(prev => prev + 1);

            let continueLecturePrompt = "";
            if( USE_GENERATED_RESPONSES ) {
                continueLecturePrompt = await handleCompileGenerateAndRevisePrompts( "draftContinueLecture", {}, 
                                                                                      "publishContinueLecture", {} );
            } else {
                continueLecturePrompt = getCompiledPrompt( "createContinueLecture", { curriculum: compiledCurriculum, lecture: lectureProgressRef.current, nextRoundTopic: nextRoundTopic } );
            }

            // TODO: The context here should be actual context, not mixed with prompt
            const continuationPrompt = `
                ${getUpdatedContext()}
                ${continueLecturePrompt}

                ${USE_TEACHING_GUIDELINES ? `Follow these teaching guidelines: ${teachingGuidelines}` : ""}
            `;
            /*
                Here's a summary of the last topic covered and where you left off: "${lectureSummary}"

                You left off here: "${lectureProgressRef.current}"
                ${USE_TEACHING_GUIDELINES ? `Follow these teaching guidelines: ${teachingGuidelines}` : ""}
            `;
            */

            generateInstructorLecture( continuationPrompt );
        } else {
            setIsProcessing(false);
            console.log("Lecture completed after reaching maximum rounds");
        }
    }, [currentRound, generateInstructorLecture, compiledCurriculum]);



    const clearCurrentSession = useCallback(() => {
        setCurrentRound(0);
        setIsProcessing(false);
        lectureProgressRef.current = "";
        lastLectureRoundRef.current = "";
        conversationHistoryRef.current = [];
    }, []);

    const setMaxRounds = useCallback((rounds: number) => {
        maxRoundsRef.current = rounds;
    }, []);





    
    const promptRealStudentToAskQuestions = useCallback(async () => {
        await postMessageAndSpeakInChunks(
            {
                message: `Now's the time to ask questions, ${getHumanStudentName()}! You can click "Next" to continue the lecture.`,
                sender: INSTRUCTOR_NAME,
                type: MessageType.CHAT
            },
        );
        unmuteStudents();
    }, [postMessageAndSpeakInChunks, getHumanStudentName, unmuteStudents]);

    const handleEndRound = useCallback(async () => {
        promptRealStudentToAskQuestions();

        setIsRoundComplete( true );

        if( AUTOMATIC_START_NEXT_ROUND ) {
            setTimeout( startNextRound, START_NEXT_ROUND_TIMEOUT );
        }
    }, [promptRealStudentToAskQuestions, startNextRound]);






    const getCurriculumDescription = useCallback( async () => {
        let curriculumDescriptionToUse = "";

        const basicLectureInfo = compiledCurriculum;

        if( USE_GENERATED_RESPONSES ) {
            curriculumDescriptionToUse = await handleCompileGenerateAndRevisePrompts( "draftCurriculum", { basicLectureInfo }, 
                                                                                      "reviseCurriculum", { basicLectureInfo } );
        }
        else {
            curriculumDescriptionToUse = await handleCompileAndExecutePrompt( "createCurriculumDescription", { basicLectureInfo } );
        }

        return curriculumDescriptionToUse;
    }, [compiledCurriculum, getCompiledPrompt, handleCompileAndExecutePrompt]);

    const getTeachingGuidelines = useCallback( async () => {
        let teachingGuidelinesToUse = "";

        if( USE_GENERATED_RESPONSES ) {
            teachingGuidelinesToUse = await handleCompileGenerateAndRevisePrompts( "draftTeachingGuidelines", { curriculum: compiledCurriculum }, 
                                                                                   "reviseTeachingGuidelines", { curriculum: compiledCurriculum } );
        }
        else {
            // TODO: Probably want to do something else for default here (e.g. use what user entered and mock-up something relevant)
            teachingGuidelinesToUse = "Be a good teacher..."; // TODO: Have from default prompt templates ?
        }

        return teachingGuidelinesToUse;
    }, [compiledCurriculum, getCompiledPrompt, handleCompileAndExecutePrompt]);

    const getRealStudentEngagementGuidelines = useCallback( async () => {
        let realStudentEngagementGuidelinesToUse = "";

        if( USE_GENERATED_RESPONSES ) {
            // TODO: NEED UPDATE BELOW BASED ON RECENT CHANGES AROUND PROMPT AND SPREADSHEET
            //realStudentEngagementGuidelinesToUse = handleCompileGenerateAndPublishPrompts( "draftInstructorBotEngageRealStudentGuidelines", { curriculum: curriculumDescription }, 
                                                                                           //"reviseInstructorBotEngageRealStudentGuidelines", { curriculum: curriculumDescription } );
            const draftPromptId = "draftInstructorBotEngageRealStudentGuidelines";
            const draftPrompt = getCompiledPrompt( draftPromptId, { curriculum: compiledCurriculum }, PromptTemplateType.GENERATED );
            //const publishPrompt = getCompiledPrompt( "publishInstructorBotEngageRealStudentGuidelines", { curriculum: curriculum.description }, PromptTemplateType.GENERATED );
            const generatedPrompt = await handleGeneratePrompt( draftPrompt );
            //const revisedPrompt = await handleReviseGeneratedPrompt( publishPrompt, generatedPrompt );

            updatePromptTemplateResponse( draftPromptId, generatedPrompt, PromptTemplateType.GENERATED );

            // TODO: Above used as PUBLISH and not really revise or whatever
            //const revisedPrompt = await handleGenerateAndRevisePrompt( draftPrompt, revisePrompt );
            //realStudentEngagementGuidelinesToUse = revisedPrompt;
            realStudentEngagementGuidelinesToUse = generatedPrompt;
        }
        else {
            realStudentEngagementGuidelinesToUse = "Be a good teacher to the student..."; // TODO: Have from default prompt templates ?
        }

        return realStudentEngagementGuidelinesToUse;
    }, [compiledCurriculum, getCompiledPrompt, handleCompileAndExecutePrompt]);








    const contextValue: AIBotsContextType = {
        isProcessing,
        currentRound,
        isRoundComplete,
        setCurrentRound
    };

    const updateContextValue: AIBotsUpdateContextType = {
        generateInstructorLecture,
        generateStudentBotResponse,
        generateInstructorResponseToStudentBot,
        generateInstructorResponseToHuman,
        generateLectureSummary,

        startNextRound,
        clearCurrentSession,
        setMaxRounds,

        initializeAIBots
    };

    return (
        <AIBotsContext.Provider value={contextValue}>
            <AIBotsUpdateContext.Provider value={updateContextValue}>
                {children}
            </AIBotsUpdateContext.Provider>
        </AIBotsContext.Provider>
    );
}





// ============================== HELPERS ==============================

function getDefaultCurriculumDescription() {

    const curriculumDescription = `
        Subject: Computer Programming
        Topic: Introduction to Computer Programming
        Keywords: Programming, Variables, Data Types, Control Structures
        Goal: Introduce Basic Programming Concepts and Problem-Solving Skills
        Objectives:
        - Understand what programming is
        - Learn about variables and data types
        - Grasp basic control structures
        Focus: Foundational Concepts in Programming Logic
        Learner Level: Beginner
        Learner Age: 12+
        Lesson Level: Introductory
        Lesson Length: 30 Minutes
        Number of Sessions: 1
        Session Length: 30 Minutes
        Quick Assessment: Knowledge Check Quiz at the End
        Learning Preference: Balance of Theory and Hands-On Applications
        Special Requests: Ensure content is engaging and interactive, with real-world examples


        Response Length: Keep all responses less than 200 words
    `;

    return curriculumDescription;
}





export {
    AIBotsContextProvider,

    useAIBots, useAIBotsUpdate,
}
